// src/Tabs.js
import React, { useState } from "react";
// import "./Tabs.css";

const Tabs = ({ tabs }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <div className="tab-wrap">
      <ul className="tab-btns">
        {tabs.map((tab, index) => (
          <li
            key={index}
            className={`tab-btn ${index === activeTabIndex ? "show" : ""}`}
            onClick={() => setActiveTabIndex(index)}
          >
            {tab.label}
          </li>
        ))}
      </ul>
      <div className="tab-contents">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab-content ${index === activeTabIndex ? "show" : ""}`}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
