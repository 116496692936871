// src/CustomModal.js
import React from "react";
// import "./CustomModal.css";

const CustomModal = ({ show, onClose, children }) => {
  return (
    <div className={`custom-modal-wrapper ${show ? "show" : ""}`}>
      <div className="custom-modal-wrapper_box">
        <div
          className="custom-view-window"
          onClick={(e) => {
            if (e.target.className === "custom-view-window") {
              console.log("Custom Background clicked");
              onClose();
            }
          }}
        >
          <div
            className="custom-modal-content-wrapper"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="custom-modal-body">{children}</div>
            <div className="custom-modal-close" onClick={onClose}>
              <p>閉じる</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
