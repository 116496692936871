// src/App.js
import React, { useState } from "react";
import "./styles/App.scss";
import Modal from "./Modal";
import CustomModal from "./CustomModal";
import Accordion from "./Accordion";
import Tabs from "./Tabs";
import HamburgerMenu from "./HamburgerMenu";
import ScrollFadeIn from "./ScrollFadeIn";
import SimpleSlider from "./Slider";

const content = [
  { id: 1, text: "モーダルの中身1" },
  { id: 2, text: "モーダルの中身2" },
  { id: 3, text: "モーダルの中身3" },
];

const tabsData = [
  {
    label: "タブ1",
    content: (
      <div>
        <p>これはタブ1の中身です。</p>
      </div>
    ),
  },
  {
    label: "タブ2",
    content: (
      <div>
        <p>タブ2の中身。</p>
      </div>
    ),
  },
  {
    label: "タブ3",
    content: (
      <div>
        <p>タブ3の中身。</p>
      </div>
    ),
  },
];

function App() {
  const [showModal, setShowModal] = useState(false);
  const [showCustomModal, setShowCustomModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleOpenModal = (index) => {
    setCurrentIndex(index);
    setShowModal(true);
  };

  const handleOpenCustomModal = () => {
    setShowCustomModal(true);
  };

  const handleCloseModal = () => {
    console.log("Modal closing");
    setShowModal(false);
  };

  const handleCloseCustomModal = () => {
    console.log("Custom Modal closing");
    setShowCustomModal(false);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + content.length) % content.length
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % content.length);
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>React モーダルサンプル</h1>
        <HamburgerMenu /> {/* ハンバーガーメニューを追加 */}
        <div className="shop-wrapper">
          <ul className="shop-lists">
            {content.map((item, index) => (
              <li key={item.id}>
                <div
                  className="js-modal-open"
                  onClick={() => handleOpenModal(index)}
                >
                  <p>モーダルオープン{item.id}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <button onClick={handleOpenCustomModal}>カスタムモーダルを開く</button>
      </header>
      <main>
        <Accordion title="アコーディオンタイトル1">
          <p>アコーディオンコンテンツ1</p>
        </Accordion>
        <Accordion title="アコーディオンタイトル2">
          <p>アコーディオンコンテンツ2</p>
        </Accordion>
        <Accordion title="アコーディオンタイトル3">
          <p>アコーディオンコンテンツ3</p>
        </Accordion>
        <Tabs tabs={tabsData} />

        <ScrollFadeIn />
      </main>
      <Modal
        show={showModal}
        onClose={handleCloseModal}
        onPrev={handlePrev}
        onNext={handleNext}
      >
        <p>{content[currentIndex].text}</p>
      </Modal>
      <CustomModal show={showCustomModal} onClose={handleCloseCustomModal}>
        <p>これはカスタムモーダルです。</p>
      </CustomModal>

      <SimpleSlider />
    </div>
  );
}

export default App;
