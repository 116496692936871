// src/HamburgerMenu.js
import React, { useState, useEffect } from "react";
// import "./HamburgerMenu.css";

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
    } else {
      document.body.style.position = "";
      document.body.style.width = "";
    }
  }, [isOpen]);

  return (
    <>
      <div
        className={`sp-header-nav_box_ham ${isOpen ? "clicked" : ""}`}
        onClick={handleToggle}
        id="js-ham"
      >
        <span className="sp-header-nav_box_ham_line line-1"></span>
        <span className="sp-header-nav_box_ham_line line-2"></span>
        <span className="sp-header-nav_box_ham_line line-3"></span>
      </div>
      <div
        className={`sp-nav_nav-list ${isOpen ? "nav-opened" : ""}`}
        id="js-sp-nav"
        onClick={handleBackgroundClick} // 背景クリックをハンドル
      >
        <nav className="link-flex">
          <ul className="sp-nav_nav-list_box" id="js-nav-scroll">
            <li className="sp-nav_nav-list_box_list">
              <a
                href="#message"
                className="menu-link"
                onClick={handleLinkClick}
              >
                <p>Message</p>
              </a>
            </li>
            <li className="sp-nav_nav-list_box_list">
              <a
                href="#service"
                className="menu-link"
                onClick={handleLinkClick}
              >
                <p>Service</p>
              </a>
            </li>
            <li className="sp-nav_nav-list_box_list">
              <a href="#news" className="menu-link" onClick={handleLinkClick}>
                <p>News</p>
              </a>
            </li>
            <li className="sp-nav_nav-list_box_list">
              <a
                href="#company"
                className="menu-link"
                onClick={handleLinkClick}
              >
                <p>Company</p>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default HamburgerMenu;
