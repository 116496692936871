// src/Modal.js
import React from "react";
// import "./Modal.css";

const Modal = ({ show, onClose, onPrev, onNext, children }) => {
  return (
    <div className={`modal-wrapper ${show ? "show" : ""}`}>
      <div className="modal-wrapper_box">
        <div className="btn-prev" onClick={onPrev}>
          <p>前へ</p>
        </div>
        <div className="btn-next" onClick={onNext}>
          <p>次へ</p>
        </div>
        <div
          className="view-window"
          onClick={(e) => {
            if (e.target.className === "view-window") {
              console.log("Background clicked");
              onClose();
            }
          }}
        >
          <div
            className="modal-content-wrapper"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="modal-body">{children}</div>
            <div className="js-modal-close btn-close" onClick={onClose}>
              <p>閉じる</p>
            </div>
            <div className="js-modal-close txt-close" onClick={onClose}>
              <p>戻る</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
