import React, { useEffect } from "react";
// import "./FadeInAnimation.css";
import { throttle } from "lodash";

const ScrollFadeIn = () => {
  useEffect(() => {
    const loadEffect = throttle(() => {
      const elements = document.querySelectorAll(".scroll-fade, .scroll-up");
      const tt = window.scrollY;
      const hh = window.innerHeight;

      elements.forEach((element) => {
        const yy = element.getBoundingClientRect().top + 400;
        if (tt + hh > yy) {
          element.classList.add("done");
        } else {
          element.classList.remove("done");
        }
      });
    }, 200);

    loadEffect();
    window.addEventListener("scroll", loadEffect);

    return () => {
      window.removeEventListener("scroll", loadEffect);
    };
  }, []);

  return (
    <div className="content02">
      <div className="wrap scroll-fade">
        <h3>ふわっとスクロール</h3>
        スクロールするとフェードインするコンテンツ
      </div>
      <div className="wrap scroll-up">
        <h3>ふわっとスクロール</h3>
        スクロールするとスクロールアップしてくるコンテンツ
      </div>
    </div>
  );
};

export default ScrollFadeIn;
